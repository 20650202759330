@import url('https://fonts.googleapis.com/css2?family=Righteous');
.App{
	height:100%;
	overflow-x:hidden;
	overflow-y:hidden;
	width:100%;
}

html{
	height:100vh;
	width:100vw;
	overflow-y:hidden;
}

body {
	font-family: system-ui;
	height:100%;
	width:100%;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern";
    text-rendering:optimizeLegibility;
    -webkit-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern";
}
#debug{
	background:black;
	color:white;
	position:absolute;
	top:0px;
	left:0px;
}
/*legacy*/

#audioModal{
	text-align:center;
}

.desktopQR{
    display: block;
    height: 35vh;
    margin:auto;
    width: auto;
}
#cancelProtect{
	float:left;
}

#cancelRestore{
	float:left;
}

.contact-avatar{
    background: grey;
    border: 2px solid grey;
    border-radius: 1em;
	display: block;
    margin-right:0.2vw;
    overflow: hidden;
}

.qrCapture{
	left:25vw;
	height:33vh;
	position:absolute;
	top:15vh;
	width:50vw;
}
.qrCapture b{
	display:block;
	margin:auto;
	text-align:center;
}

.restoreWalletCard{
	left:5vw;
	position:fixed;
	text-align:center;
	top:40vh;
	width:90vw;
	word-break:break-word;
	z-index:999;
}

.restoreWalletCard .card{
	padding:0.8em;
}

@media only screen and (max-width: 600px) {
	.restoreWalletCard {
		top:30vh;
	}
}
.secureWalletCard {
	left:5vw;
	position:fixed;
	text-align:center;
	top:40vh;
	width:90vw;
	word-break:break-word;
	z-index:999;
}

.secureWalletCard .card{
	padding:0.8em;
}

@media only screen and (max-width: 600px) {
	.secureWalletCard {
		top:30vh;
	}
}

.secureWalletCard h4{
	text-align:center;
}

#secureWalletPromise{
	text-align:center;
	width:100%;
}
.transactionCard{
	height:55vh;
	overflow-y:scroll;
	text-align:left;
	word-break:break-all;
}

.transactionPopup{
    display: block;
    height: 60vh;
    left: 5vw;
    margin: auto;
    position: absolute;
    top: 10vh;
    width: 90%;
    z-index: 99;
}

.txHistory{
	height:98vh;
	overflow-y:scroll;
	word-break:break-word;
}
/* desktop */
#addContactActions{
	display:block;
}

#addContactButton{
	float:right;
}

#addContact button{
	display:block;
	margin:auto;
}

#addContactForm{
	background:blue;
	height:5vh;
	position:absolute;
	top:35%;
	width:45%;
	z-index:99;
}
#appRow{
	display:flex;
}
.avatar{
	height:55px;
	margin-left:1.2em;
}

#barAccountInfo{
	display:flex;
	margin:auto;
}

.cardAvatar{
	background-size:contain !important;
	display:block !important;
	height:128px;
	margin:auto;
	width:auto;
}

#chainSelectionButtons{
    display: block;
    float: right;
    margin-bottom: auto;
    margin-top: auto;
}

#chat{
	animation-name:pulsex;
	animation-direction:alternate;
	animation-duration:1s;
    animation-iteration-count: 20;	
    animation-delay:0.1s;
    animation-timing-function:linear;
	/*background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(145,85,117,1) 26%, rgba(0,212,255,1) 100%);*/
	/*border:0.2em solid black;*/
	height:79vh;
	overflow-x:hidden;
	overflow-y:auto-scroll;
	width:99%;
}

#chatCol{
	background:lightgrey;
	overflow-x:hidden;
	overflow-y:auto-scroll;
}

#chatHolderDesktop{
	margin:auto;
	width:100%;
}
#charCount{
	color:red;
}

.comrev{
	background:black;
    color: white;
    font-family: system-ui;
    font-size: x-large;
    overflow: hidden;
    padding:0.4em;
}

.contactCol{
	height:87vh;
	margin:auto;
	overflow-y:scroll;
}

.contactCol .col-4{
	border:2px solid blue;
}

.contactHolder{
	background:white;
	border-left:3px solid black;
	display:block;
	height:100%;
	margin:auto;
	overflow-y:scroll;
	width:100%;
}

.contactImg{
	margin:5px;
	width:25%;
}

.contactInfo{
	font-size:x-small;
	word-break:break-all;
}

.contactSlice{
	background:#3f51b5;
	border:2px solid lightgrey;
	color:white;
	font-size:small;
	overflow-x:hidden;
	position:sticky;
	width:25%;
}

.credits{
	text-align:center;
	width:100%;
}

.currentContactAvatar{
    width:50%;
}

.currentContactAvatar p{
	float:right;
}

.currentContactAvatar img{
	height:55px;
}

#desktopColBar{
	background:white;
	float:left;
	height:100vh;
}
#desktopColView{
	background:lightgrey;
	display:block;
	height:100vh;
	width:100%;
}

#gitLink{
	bottom:0px;
	padding:0.8vw;
	position:fixed;
}

#logo{
	height: 96%;
	padding-left:1vw;
	padding-top:1vh;
}

.loginButtons{
	animation-name:pulsex;
	animation-direction:alternate;
	animation-duration:1s;
    animation-iteration-count: infinite;	
    animation-delay:0.1s;
    animation-timing-function:linear;
	background:lightslategrey;
	border:0.4em solid grey;
	border-radius:0.5em;
	left:50%;
	position:absolute;
	top:40%;
	z-index:99;
}

@media only screen and (max-width: 600px) {
  .loginButtons {
    left: 33%;
    top: 33%;
  }
}

#newMessageForm{
	width:100%
}

.msgContact{
	display:flow-root;
}

.msgContact p{
	background:whitesmoke;
	border:1px solid grey;
	border-radius:1em;
	border-bottom-left-radius:0em;
	color:black;
	float:left;
	margin-left:0.9em;
	margin-right:0.8em;
	margin-top:0.8em;
	margin-bottom:0.8em;
	padding:1% 3%;
	word-break:break-all;
}

.msgSelf{
	display:flow-root;
}

.msgSelf p{
	background-color:powderblue;
	border:1px solid white;
	border-radius:1em;
	border-bottom-right-radius:0em;
	color:black;
	float:right;
	margin:0.8em;
	padding:1% 3%;
	word-break:break-all;
}


#progressBar{
	position:absolute;
	top:0px;
	width:100vw;
	z-index:99;
}

#protectButton{
	float:right;
}

.qrCapture{
	left:25vw;
	height:33vh;
	position:absolute;
	top:15vh;
	width:50vw;
}

.recentContacts{
	width:100px;
}

.recentContacts img{
	height:auto;
	width:100%;
}

.restoreWalletCard #titleButton{
	display:block;
	margin:auto;
	text-align:center !important;
}

#secureButtons{
	width:100%;
}

#secureMessageForm{
	width:100%
}

.secureWalletCard #titleButton{
	display:block;
	margin:auto;
}

.settingsCard{
	display:block;
	margin-left:auto;
	margin-right:auto;
	margin-top:3%;
	width:80%;
}

#settingsAccount{
	display:block;
	text-align:center;
}

.settingsContent p{
	text-align:center;
}

.settingsPanel{
	display:block;
	height:100%;
	overflow-x:hidden;
	overflow-y:auto-scroll;
	word-break:break-all;
}

#topBar{
	background:black;
	color:white;
	display:flex;
	height:8vh;
	width:100%;
	word-break:break-all;
}

#walletConnectButton{
	background: black;
	height:50px;
	text-align:right;
	-moz-text-align:right !important;
	-webkit-text-align:right;
	-o-text-align:right;
}

#walletConnectButton button{
	background:black;
	float:right;
}

.userInput{
	display:flex;
	margin:auto;
	width:95%;
}
/*end desktop*/

/*extras*/
#extras{
	display:none;
	margin-top:2em;
	padding-left:2em;
}

#logRow{
	display:block;
	height:10vh;
	margin:1em;
	z-index:9999999;
}

#logs{
	display:block;
	border:1px solid grey;
	font-size:large;
	height:10vh;
	width:100%;
}
/*extras*/

/*Qr*/
#qrView{
    height: auto;
    left: 26vw;
	position: absolute;
    top: 3vh;
    width: 50vw;
}
/*Qr*/

/*Stage*/
#background{
    background-repeat: no-repeat;
    background:white;
    margin: auto;
    position:absolute;
    width: 100vw;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.characterSelect{
    background-image:url(/images/vs3.jpg);
	background-repeat:no-repeat;
	background-position:center;
	background-size:cover;
    overflow:hidden;
    width:100vw;    	
}

.characterSelectRow{
	display:flex;
	overflow-x:scroll;
    width: 100%;
}

.characterSelectRow div img{
	border:1px solid black;
	height:8vh;
	width:7vh;
}

.characterSelectRow div img:hover{
	border:2px solid #1d74a7;
	cursor:pointer;
	height:8.5vh;
	width:7vh;
}

/* character name button CSS */
.button-64 {
  align-items: center;
  background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  margin:3px;
}

.button-64:active,
.button-64:hover {
  outline: 0;
}

.button-64 span {
  background-color: rgb(5, 6, 45);
  padding: 16px 24px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.button-64:hover span {
  background: none;
}

@media (min-width: 768px) {
  .button-64 {
    font-size: 1.2em;
    min-width: 8vw;
  }
}


.button-65 {
  align-items: center;
  background-image: linear-gradient(144deg,indigo, darkred 50%,red);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 3vw;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 4vw;
  padding: 0.2vw;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  margin:3px;
}

.button-65:active,
.button-65:hover {
  outline: 0;
}

.button-65 span {
  background-color: rgb(5, 6, 45);
  padding: 1.5vh 1vw;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.button-65:hover span {
  background: "none";
  background-color:darkred;
}

@media (min-width: 768px) {
  .button-65 {
    font-size: 1.5em;
    min-width: 10vw;
  }
}
/* character name button */

#playerPortraitHolder{
	display:block;
	width:100%;
	overflow:hidden;
	align-items:center;
	text-align:center;
	padding-top:15vh;

}

#player1Portrait{
	margin-right:38vw;
	margin-bottom:5vh;
	width:25vw;
	overflow:hidden;
}

#player2Portrait{
	margin-left:0vw;
	width:25vw;
	margin-bottom:5vh;	
	transform:rotateY(180deg);
}

@media only screen and (max-width: 600px) {
	#player1Portrait {
		margin-right:30vw;
		margin-bottom:8vh;
		
	}
	#player2Portrait{
		width:25vw;
		margin-bottom:8vh;
	}
}

.player2Waiting{
	font-size:x-large !important;
}

.contractView{
	background:black !important;
	display:block;
	font-size:large;
	height:50vh;
	margin:auto;
	padding:1vw;	
	width:100%;
}

#contractViewHolder{
	left:33vw;
	position:absolute;
	width:33%;
	z-index:25;
}

.contractView .contractInfo{
	height:100%;
	overflow-y:scroll;

}

.contractView progress{
	width:100%;
}

#gameHolder{
	opacity:0;
	z-index:-1;
	transition:opacity 1s;
}

#gameTimer{
	height:2vh;
	font-size:medium;
}

.gameTimers{
	height:1.2vh;
    width: 100%;
}

/*
#gameIframe{
    background-image: url("/images/grid1.jpg");
    background-size: cover;
    background-position: bottom;
}
*/

#homeButtons{
	display:flex;
	margin-left:33%;
	float:center;
	text-align:center;
}

#homeButton{
	display:flex;
	padding-left:45vw;
	width:100%;
}

iframe{
	border:0em solid black;
	border-width:none;
	display:block;
	margin:auto;
	width:100vw;
	height:100vh;
}

.mainStartButton{
	display:block;
	height:95vh;
	width:100%;
	margin:auto;
	overflow:hidden;
}

.mainStartButton button{
	font-size:2.5vh;
	height:5vh;
	display:block;
	margin:auto;
	margin-top:15%;
}

#moveTimeout{
	display:block;
	margin:auto;
	width:20%;
}

#playerOptions{
    position: absolute;
    top:90vh;
    width:100%;
}

#playerOptions button{
	font-size:xx-large;
}

#playerOptions div{
	display:flex;
	margin:auto;
}

#player1Stats b{
	color:wheat;
	font-size:large;
}

#player1Stats{
	left:33%;
	overflow:hidden;
	padding:0.2em;
	position:fixed;
	top:6%;
	width:40%;
}

.stageHolder{
	background:black;
	background-image:url(/images/landing_logo.png);
	background-repeat:no-repeat;
	background-position:center;
	background-size:auto;
    height:100vh;
	width:100%;
	overflow:hidden;
}

#title{
	display:flex;
}

.classicDiv1{
  left:32vw;
  position:relative;
  top:22vh;
  width:30vw !important;
}

.classicDiv1 #wagerInputButton:hover{
  cursor:default;
  border:0px solid black !important;
}

#kohOption{
	color:lightblue;
}

.largeEmoji{
	font-size:xx-large;
}

.shine{
	width:100%; /*Make sure the animation is over the whole element*/
    -webkit-animation-name: ShineAnimation;
    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(.1,.80,.98,.47);
}


#wagerAmount{
	display: inline-block;
    margin: auto;
    height:3vh;
    width:6vw;
}

#wagerButton{
	animation-name:pulse2;
	animation-direction:alternate;
	animation-duration:0.8s;
    animation-iteration-count: infinite;	
    animation-delay:0.1s;
    animation-timing-function:linear;
    width:100vw;
}

.wagerDiv{
	border: 1px solid white;
	display:flex;
	width:100%;
}

#wagerButtons{
	display:flex;
	margin:auto;
	width:25vw;
}

#wagerCollect{
	background: linear-gradient(45deg,lightgreen 0%, lightgreen 60%, forestgreen 100%);
	width:30vw;
}

#wagerTrash{
	background: linear-gradient(45deg,brown 0%, sandybrown 60%, rosybrown 100%);
	width:30vw;
}

.wagerDisclaimer{
	background:black;
	border:0.1em solid red;
	color:white;
	font-size:normal;
	overflow:hidden;
}

.wagerDisclaimer button{
	width:100%;
}

#wagerOption{
	color:gold;
}

#wagerStats{
	background: linear-gradient(45deg,white 0%, lightblue 40%, grey 100%);
	width:30vw;
}

/*End Stage*/

::-webkit-scrollbar {
    width: 5px!important;
    height: 3px!important;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.2);
}

::-webkit-scrollbar-track {
    background: hsla(0,0%,100%,.1);
}

@keyframes pulse {
  from {background: radial-gradient(circle,red 0%, purple 60%, black 100%);}
  to {background: radial-gradient(circle,#746d80 0%, purple 40%, black 100%);}
}

@keyframes pulse2 {
  from {background: red;}
  to {background: black;}
}

@keyframes pulsex {
  from {	border:0.5em solid purple;background:black;}
  to {	border:0.5em solid red;background:red;}
}

/* https://patrickdesjardins.com/blog/css3-shining-animation-for-html-element */
@keyframes ShineAnimation{
    from {
        background-repeat:no-repeat;
        background-image:-webkit-linear-gradient(
            top left,
            rgba(255, 255, 255, 0.0) 0%,
            rgba(255, 255, 255, 0.0) 45%,
            rgba(255, 255, 255, 0.5) 48%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 0.5) 52%,
            rgba(255, 255, 255, 0.0) 57%,
            rgba(255, 255, 255, 0.0) 100%
        );
        background-position:-250px -250px;
        background-size: 600px 600px
    }
    to {
        background-repeat:no-repeat;
        background-position:250px 250px;
    }
}

/*** Landing Page ***/

#Landing_body{
	background-color:#131416;
	height:100vh;
	min-height:100vh;
	overflow:hidden;
	-webkit-font-smoothing:antialiased;
}

.Landing_card{
	background:black;
	border:0.3vw solid #411733;
	padding:0.9vh;
	margin-left:30vw;
	margin-top:5vh;
	color:white;
	cursor:pointer;
	font-size:xx-large;
	text-align:center;
	transition:1.5s;
}

.Landing_card:hover{
	border:0.3vw solid white;
}	

.Landing_card a{
	color:white;
}			

#Landing_cards{
	top:20vh;
	left:12.5vw;
	position: absolute;
}

#Landing_movie{
	display: block;
	width: 25%;
	border:0.1vw solid #411733;
	border-radius:1vh;
	overflow: hidden;
	position: absolute;
	left:12vw;
	top:25vh;
	height:15vw;
	overflow:hidden;
}

#Landing_movie video{
	height:105%;
	width:auto;
}

#Landing_site{
	background:url("/images/l337a_moshed.jpg");
	background-position:right;
	background-size:contain;
	background-repeat:no-repeat;
	height:95%;
	width:80%;
	display:block;
}	
#Landing_support{
	display:table;
	margin:auto;
	align-items:center;
}
#Landing_support img{
	margin-left:1vw;
	height:4vh;
	width:auto;
}
